import { NUMBER_INPUT_POSITIVE, YES_NO_UNKNOWN } from '@/forms/schemas/generators'
import { OUTSIDE_JOINERY_MATERIAL_OPTIONS, TELECOM_CONNECTION_OPTIONS, VENTILATION_SYSTEM_OPTIONS } from '@/forms/selectOptions'
import { getPropertyComfort, updatePropertyComfort } from '@/services/properties'
import { successModal, errorModal } from '@/modalMessages'

const schema = [
  {
    component: 'div',
    class: 'tw-my-4 tw-grid md:tw-grid-cols-2 xl:tw-grid-cols-4 tw-gap-4',
    children: [
      {
        name: 'adapted_for_the_disabled',
        label: 'Aangepast voor personen met een handicap',
        outerClass: 'tw-m-0',
        ...YES_NO_UNKNOWN
      },
      {
        name: 'alarm',
        label: 'Alarm',
        outerClass: 'tw-m-0',
        ...YES_NO_UNKNOWN
      },
      {
        type: 'select',
        name: 'telecom_connection',
        label: 'Aansluiting telecom',
        placeholder: 'Selecteer telecomaansluiting',
        options: TELECOM_CONNECTION_OPTIONS,
        outerClass: 'tw-m-0'
      },
      {
        type: 'select',
        name: 'bathroom_type',
        label: 'Badkamertype',
        options: {
          0: 'Onbekend',
          1: 'Ingericht',
          2: 'Niet ingericht'
        },
        outerClass: 'tw-m-0'
      },
      {
        name: 'blinds',
        label: 'Rolluiken',
        outerClass: 'tw-m-0',
        ...YES_NO_UNKNOWN
      },
      {
        type: 'translatable',
        name: 'blinds_description',
        label: 'Rolluiken - extra informatie',
        outerClass: 'md:tw-col-span-2 xl:tw-col-span-4'
      },
      {
        name: 'distribution',
        label: 'Distributie',
        outerClass: 'tw-m-0',
        ...YES_NO_UNKNOWN
      },
      {
        name: 'elevator',
        label: 'Lift',
        outerClass: 'tw-m-0',
        ...YES_NO_UNKNOWN
      },
      {
        type: 'translatable',
        name: 'flooring_description',
        label: 'Bevloering - extra informatie',
        outerClass: 'md:tw-col-span-2 xl:tw-col-span-4'
      },
      {
        name: 'furnished',
        label: 'Gemeubeld',
        outerClass: 'tw-m-0',
        ...YES_NO_UNKNOWN
      },
      {
        name: 'home_automation',
        label: 'Domotica',
        outerClass: 'tw-m-0',
        ...YES_NO_UNKNOWN
      },
      {
        name: 'intercom',
        label: 'Parlofoon',
        outerClass: 'tw-m-0',
        ...YES_NO_UNKNOWN
      },
      {
        name: 'internet_connection',
        label: 'Aansluiting internet',
        outerClass: 'tw-m-0',
        ...YES_NO_UNKNOWN
      },
      {
        type: 'select',
        name: 'kitchen_type',
        label: 'Keukentype',
        placeholder: 'Selecteer keukentype',
        options: {
          0: 'Onbekend',
          1: 'Geïnstalleerd',
          2: 'Gedeeltelijk geïnstalleerd',
          3: 'Niet geïnstalleerd',
          4: 'Hyper-geïnstalleerd',
          5: 'Amerikaans gedeeltelijk geïnstalleerd',
          6: 'Amerikaans geïnstalleerd',
          7: 'Amerikaans hyper-geïnstalleerd'
        },
        outerClass: 'tw-m-0'
      },
      {
        type: 'select',
        name: 'outside_joinery_material',
        label: 'Schrijnwerk buiten',
        placeholder: 'Schrijnwerk buiten',
        options: OUTSIDE_JOINERY_MATERIAL_OPTIONS,
        outerClass: 'tw-m-0'
      },
      {
        name: 'pets_allowed',
        label: 'Huisdieren toegelaten',
        outerClass: 'tw-m-0',
        ...YES_NO_UNKNOWN
      },
      {
        type: 'translatable',
        name: 'pets_allowed_description',
        label: 'Huisdieren toegelaten - extra informatie',
        outerClass: 'md:tw-col-span-2 xl:tw-col-span-4'
      },
      {
        name: 'rain_pit',
        label: 'Regenput',
        outerClass: 'tw-m-0',
        ...YES_NO_UNKNOWN
      },
      {
        name: 'rain_pit_capacity',
        label: 'Inhoud regenput',
        placeholder: 'Inhoud regenput',
        outerClass: 'tw-m-0',
        ...NUMBER_INPUT_POSITIVE
      },
      {
        name: 'septic_tank',
        label: 'Septische put',
        outerClass: 'tw-m-0',
        ...YES_NO_UNKNOWN
      },
      {
        name: 'cesspool',
        label: 'Beerput',
        outerClass: 'tw-m-0',
        ...YES_NO_UNKNOWN
      },
      {
        name: 'sewerage',
        label: 'Aansluiting riolering',
        outerClass: 'tw-m-0',
        ...YES_NO_UNKNOWN
      },
      {
        name: 'telephone_line_connection',
        label: 'Aansluiting telefoonlijn',
        outerClass: 'tw-m-0',
        ...YES_NO_UNKNOWN
      },
      {
        name: 'videophone',
        label: 'Videofoon',
        outerClass: 'tw-m-0',
        ...YES_NO_UNKNOWN
      },
      {
        name: 'water_connection',
        label: 'Aansluiting waternet',
        outerClass: 'tw-m-0',
        ...YES_NO_UNKNOWN
      },
      {
        name: 'water_softener',
        label: 'Waterverzachter',
        outerClass: 'tw-m-0',
        ...YES_NO_UNKNOWN
      },
      {
        name: 'water_well',
        label: 'Waterput',
        outerClass: 'tw-m-0',
        ...YES_NO_UNKNOWN
      },
      {
        name: 'bike_storage',
        label: 'Fietsenberging',
        outerClass: 'tw-m-0',
        ...YES_NO_UNKNOWN
      },
      {
        type: 'select',
        name: 'ventilation_system',
        label: 'Ventilatiesysteem',
        placeholder: 'Ventilatiesysteem',
        options: VENTILATION_SYSTEM_OPTIONS,
        outerClass: 'tw-m-0'
      }
    ]
  }
]

const actions = {
  async load (propertyId) {
    try {
      const response = await getPropertyComfort(propertyId)
      return response.data
    } catch (error) {
      errorModal('Fout bij het laden van comfortgegevens, probeer het opnieuw.')
      throw error
    }
  },
  async submit (propertyId, data) {
    try {
      const response = await updatePropertyComfort(propertyId, data)
      successModal('De wijzigingen aan de comfortgegevens zijn succesvol opgeslagen')
      return response
    } catch (error) {
      errorModal('Er ging iets fout bij het wijzigen van de comfortgegevens dit pand. Gelieve nogmaals te proberen.')
      throw error
    }
  }
}

export default {
  heading: 'Comfortgegevens',
  schema,
  actions
}
